import { FunctionComponent, useEffect, useState } from "react";
import { PageNotFound } from "./components/PageNotFound";

export const PageNotFoundListener: FunctionComponent = () => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  const checkForPageNotFound = () => {
    const el = document.getElementById("PAGE_NOT_FOUND");

    setIsVisible(!!el);
  };

  useEffect(() => {
    window.addEventListener("single-spa:routing-event", checkForPageNotFound);

    checkForPageNotFound();

    return () =>
      window.removeEventListener(
        "single-spa:routing-event",
        checkForPageNotFound
      );
  }, []);

  return isVisible ? <PageNotFound /> : null;
};
