import {
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";

import { navigateToUrl } from "single-spa";

import { RouterContext } from "./RouterContext";

interface Props {
  children: ReactNode;
}

export const RouterProvider: FunctionComponent<Props> = ({ children }) => {
  const [path, setPath] = useState(window.location.pathname);

  const setPathFromLocation = useCallback(() => {
    setPath(window.location.pathname);
  }, []);

  useEffect(() => {
    window.addEventListener("single-spa:routing-event", setPathFromLocation);

    return () =>
      window.removeEventListener(
        "single-spa:routing-event",
        setPathFromLocation
      );
  }, [setPathFromLocation]);

  const isActive = useCallback(
    (href: string, exact = false) => {
      if (exact) return path === href;
      return path.startsWith(href);
    },
    [path]
  );

  const contextValue = useMemo(
    () => ({
      path,
      navigateToUrl,
      isActive,
    }),
    [path, isActive]
  );

  const memoizedChildren = useMemo(() => <>{children}</>, [children]);
  return (
    <RouterContext.Provider value={contextValue}>
      {memoizedChildren}
    </RouterContext.Provider>
  );
};
