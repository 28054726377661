import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import { Typography } from "@destination/components";

export const PageNotFound: FunctionComponent = () => {
  const { t } = useTranslation(undefined, {
    keyPrefix: "page-not-found",
  });

  return (
    <div className="flex flex-col gap-3">
      <Typography variant="pageTitle">{t("title")}</Typography>
      <Typography variant="description">{t("message")}</Typography>
    </div>
  );
};
