import { FunctionComponent } from "react";

import { useRouter } from "../hooks/useRouter";

export type Props = {
  href: string;
  exact?: boolean;
  onClick?: () => void;
  children: ({ isActive }: { isActive: boolean }) => JSX.Element;
};

export const MenuItem: FunctionComponent<Props> = ({
  href,
  exact,
  onClick,
  children,
}) => {
  const { isActive, navigateToUrl } = useRouter();

  const handleOnClick = () => {
    if (onClick) {
      onClick();
    } else {
      navigateToUrl(href);
    }
  };

  return (
    <a
      className="cursor-pointer"
      onClick={handleOnClick}
      data-testid="menuItem"
    >
      {children({ isActive: isActive(href, exact) })}
    </a>
  );
};
