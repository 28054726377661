import { FunctionComponent, useContext } from "react";

import { SidebarContext } from "@destination/components";
import { MicroFrontends } from "./MicroFrontends";
import { PageNotFoundListener } from "./PageNotFoundListener";

export const Main: FunctionComponent = () => {
  const { isCollapsed } = useContext(SidebarContext);

  return (
    <div
      className={`flex flex-1 w-screen h-screen box-border ${
        isCollapsed ? "pl-[48px]" : "pl-[150px]"
      }`}
    >
      <main
        id="main-content"
        className="flex flex-1 w-full flex-col pb-[53px] pt-16  pr-[120px] transition-all duration-200 sm:ml-12 sm:pl-2 sm:pr-8"
      >
        <MicroFrontends />
        <PageNotFoundListener />
      </main>
    </div>
  );
};
