import { createContext } from "react";

interface IRouterContext {
  path: string;
  navigateToUrl: (url: string) => void;
  isActive: (href: string, exact: boolean | undefined) => boolean;
}

export const RouterContext = createContext<IRouterContext>({
  path: undefined!,
  navigateToUrl: () => {},
  isActive: () => false,
});
